import { c as r } from "./index-31854cc2.js";
const t = r(
  `outline-0 ml-2 
  placeholder:text-gray-neutral-70 
  flex w-full 
  bg-transparent py-2 
  ring-offset-background file:border-0 file:bg-transparent file:font-medium  focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-50 border-0`,
  {
    variants: {
      variant: {
        sm: "h-8 text-sm placeholder:text-sm",
        md: "h-12 text-md placeholder:text-md",
        lg: "h-16 text-lg placeholder:text-lg"
      }
    },
    defaultVariants: {
      variant: "md"
    }
  }
), a = r(
  "flex flex-row rounded-md border border-input bg-transparent items-center px-3 box-border",
  {
    variants: {
      variant: {
        sm: "h-8",
        md: "h-12",
        lg: "h-16"
      },
      error: {
        true: "border-destructive"
      }
    },
    defaultVariants: {
      variant: "md",
      error: !1
    }
  }
), o = r(
  "flex flex-row rounded-md border border-input bg-transparent items-center px-3 box-border",
  {
    variants: {
      error: {
        true: "border-destructive"
      }
    },
    defaultVariants: {
      error: !1
    }
  }
);
export {
  t as I,
  o as T,
  a as W
};
