import { j as A } from "./jsx-runtime-944c88e2.js";
import { $ as ie, _ as C } from "./index-bf9f2594.js";
import * as L from "react";
import { useState as H, useRef as E, createElement as c, useCallback as q, forwardRef as P, useEffect as I, useMemo as Ie, Fragment as ce } from "react";
import { createPortal as Ae } from "react-dom";
import { b as Fe, $ as z, a as h } from "./index-5499c00c.js";
import { $ as Oe } from "./index-a88fc5a6.js";
import { $ as Le, a as Me } from "./index-61f4edff.js";
import { $ as ke, a as Ke, b as Ve } from "./index-6b71901e.js";
import { $ as je } from "./index-e1c48dc5.js";
import { $ as M, a as We } from "./index-14f22d4f.js";
import { $ as de } from "./index-1ac05127.js";
import { c as He } from "./index-31854cc2.js";
import { c as k } from "./utils-428888d8.js";
import Ue from "./Icon.es.js";
const ue = "ToastProvider", [re, Xe, Ye] = Oe("Toast"), [le, Nt] = Le("Toast", [
  Ye
]), [Be, G] = le(ue), pe = (e) => {
  const { __scopeToast: t, label: o = "Notification", duration: r = 5e3, swipeDirection: i = "right", swipeThreshold: l = 50, children: p } = e, [f, b] = H(null), [s, y] = H(0), T = E(!1), K = E(!1);
  return /* @__PURE__ */ c(re.Provider, {
    scope: t
  }, /* @__PURE__ */ c(Be, {
    scope: t,
    label: o,
    duration: r,
    swipeDirection: i,
    swipeThreshold: l,
    toastCount: s,
    viewport: f,
    onViewportChange: b,
    onToastAdd: q(
      () => y(
        (S) => S + 1
      ),
      []
    ),
    onToastRemove: q(
      () => y(
        (S) => S - 1
      ),
      []
    ),
    isFocusedToastEscapeKeyDownRef: T,
    isClosePausedRef: K
  }, p));
};
pe.propTypes = {
  label(e) {
    if (e.label && typeof e.label == "string" && !e.label.trim()) {
      const t = `Invalid prop \`label\` supplied to \`${ue}\`. Expected non-empty \`string\`.`;
      return new Error(t);
    }
    return null;
  }
};
const qe = "ToastViewport", ze = [
  "F8"
], te = "toast.viewportPause", oe = "toast.viewportResume", Ge = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, hotkey: r = ze, label: i = "Notifications ({hotkey})", ...l } = e, p = G(qe, o), f = Xe(o), b = E(null), s = E(null), y = E(null), T = E(null), K = ie(t, T, p.onViewportChange), S = r.join("+").replace(/Key/g, "").replace(/Digit/g, ""), N = p.toastCount > 0;
  I(() => {
    const a = (x) => {
      var u;
      r.every(
        ($) => x[$] || x.code === $
      ) && ((u = T.current) === null || u === void 0 || u.focus());
    };
    return document.addEventListener("keydown", a), () => document.removeEventListener("keydown", a);
  }, [
    r
  ]), I(() => {
    const a = b.current, x = T.current;
    if (N && a && x) {
      const u = () => {
        if (!p.isClosePausedRef.current) {
          const w = new CustomEvent(te);
          x.dispatchEvent(w), p.isClosePausedRef.current = !0;
        }
      }, m = () => {
        if (p.isClosePausedRef.current) {
          const w = new CustomEvent(oe);
          x.dispatchEvent(w), p.isClosePausedRef.current = !1;
        }
      }, $ = (w) => {
        !a.contains(w.relatedTarget) && m();
      }, v = () => {
        a.contains(document.activeElement) || m();
      };
      return a.addEventListener("focusin", u), a.addEventListener("focusout", $), a.addEventListener("pointermove", u), a.addEventListener("pointerleave", v), window.addEventListener("blur", u), window.addEventListener("focus", m), () => {
        a.removeEventListener("focusin", u), a.removeEventListener("focusout", $), a.removeEventListener("pointermove", u), a.removeEventListener("pointerleave", v), window.removeEventListener("blur", u), window.removeEventListener("focus", m);
      };
    }
  }, [
    N,
    p.isClosePausedRef
  ]);
  const d = q(({ tabbingDirection: a }) => {
    const u = f().map((m) => {
      const $ = m.ref.current, v = [
        $,
        ...pt($)
      ];
      return a === "forwards" ? v : v.reverse();
    });
    return (a === "forwards" ? u.reverse() : u).flat();
  }, [
    f
  ]);
  return I(() => {
    const a = T.current;
    if (a) {
      const x = (u) => {
        const m = u.altKey || u.ctrlKey || u.metaKey;
        if (u.key === "Tab" && !m) {
          const V = document.activeElement, F = u.shiftKey;
          if (u.target === a && F) {
            var v;
            (v = s.current) === null || v === void 0 || v.focus();
            return;
          }
          const D = d({
            tabbingDirection: F ? "backwards" : "forwards"
          }), U = D.findIndex(
            (n) => n === V
          );
          if (ee(D.slice(U + 1)))
            u.preventDefault();
          else {
            var w, _;
            F ? (w = s.current) === null || w === void 0 || w.focus() : (_ = y.current) === null || _ === void 0 || _.focus();
          }
        }
      };
      return a.addEventListener("keydown", x), () => a.removeEventListener("keydown", x);
    }
  }, [
    f,
    d
  ]), /* @__PURE__ */ c(ke, {
    ref: b,
    role: "region",
    "aria-label": i.replace("{hotkey}", S),
    tabIndex: -1,
    style: {
      pointerEvents: N ? void 0 : "none"
    }
  }, N && /* @__PURE__ */ c(se, {
    ref: s,
    onFocusFromOutsideViewport: () => {
      const a = d({
        tabbingDirection: "forwards"
      });
      ee(a);
    }
  }), /* @__PURE__ */ c(re.Slot, {
    scope: o
  }, /* @__PURE__ */ c(M.ol, C({
    tabIndex: -1
  }, l, {
    ref: K
  }))), N && /* @__PURE__ */ c(se, {
    ref: y,
    onFocusFromOutsideViewport: () => {
      const a = d({
        tabbingDirection: "backwards"
      });
      ee(a);
    }
  }));
}), Je = "ToastFocusProxy", se = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, onFocusFromOutsideViewport: r, ...i } = e, l = G(Je, o);
  return /* @__PURE__ */ c(de, C({
    "aria-hidden": !0,
    tabIndex: 0
  }, i, {
    ref: t,
    style: {
      position: "fixed"
    },
    onFocus: (p) => {
      var f;
      const b = p.relatedTarget;
      !((f = l.viewport) !== null && f !== void 0 && f.contains(b)) && r();
    }
  }));
}), J = "Toast", Qe = "toast.swipeStart", Ze = "toast.swipeMove", et = "toast.swipeCancel", tt = "toast.swipeEnd", ot = /* @__PURE__ */ P((e, t) => {
  const { forceMount: o, open: r, defaultOpen: i, onOpenChange: l, ...p } = e, [f = !0, b] = Fe({
    prop: r,
    defaultProp: i,
    onChange: l
  });
  return /* @__PURE__ */ c(je, {
    present: o || f
  }, /* @__PURE__ */ c(fe, C({
    open: f
  }, p, {
    ref: t,
    onClose: () => b(!1),
    onPause: z(e.onPause),
    onResume: z(e.onResume),
    onSwipeStart: h(e.onSwipeStart, (s) => {
      s.currentTarget.setAttribute("data-swipe", "start");
    }),
    onSwipeMove: h(e.onSwipeMove, (s) => {
      const { x: y, y: T } = s.detail.delta;
      s.currentTarget.setAttribute("data-swipe", "move"), s.currentTarget.style.setProperty("--radix-toast-swipe-move-x", `${y}px`), s.currentTarget.style.setProperty("--radix-toast-swipe-move-y", `${T}px`);
    }),
    onSwipeCancel: h(e.onSwipeCancel, (s) => {
      s.currentTarget.setAttribute("data-swipe", "cancel"), s.currentTarget.style.removeProperty("--radix-toast-swipe-move-x"), s.currentTarget.style.removeProperty("--radix-toast-swipe-move-y"), s.currentTarget.style.removeProperty("--radix-toast-swipe-end-x"), s.currentTarget.style.removeProperty("--radix-toast-swipe-end-y");
    }),
    onSwipeEnd: h(e.onSwipeEnd, (s) => {
      const { x: y, y: T } = s.detail.delta;
      s.currentTarget.setAttribute("data-swipe", "end"), s.currentTarget.style.removeProperty("--radix-toast-swipe-move-x"), s.currentTarget.style.removeProperty("--radix-toast-swipe-move-y"), s.currentTarget.style.setProperty("--radix-toast-swipe-end-x", `${y}px`), s.currentTarget.style.setProperty("--radix-toast-swipe-end-y", `${T}px`), b(!1);
    })
  })));
}), [rt, nt] = le(J, {
  onClose() {
  }
}), fe = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, type: r = "foreground", duration: i, open: l, onClose: p, onEscapeKeyDown: f, onPause: b, onResume: s, onSwipeStart: y, onSwipeMove: T, onSwipeCancel: K, onSwipeEnd: S, ...N } = e, d = G(J, o), [a, x] = H(null), u = ie(
    t,
    (n) => x(n)
  ), m = E(null), $ = E(null), v = i || d.duration, w = E(0), _ = E(v), V = E(0), { onToastAdd: F, onToastRemove: Q } = d, O = z(() => {
    var n;
    (a == null ? void 0 : a.contains(document.activeElement)) && ((n = d.viewport) === null || n === void 0 || n.focus()), p();
  }), D = q((n) => {
    !n || n === 1 / 0 || (window.clearTimeout(V.current), w.current = (/* @__PURE__ */ new Date()).getTime(), V.current = window.setTimeout(O, n));
  }, [
    O
  ]);
  I(() => {
    const n = d.viewport;
    if (n) {
      const g = () => {
        D(_.current), s == null || s();
      }, R = () => {
        const j = (/* @__PURE__ */ new Date()).getTime() - w.current;
        _.current = _.current - j, window.clearTimeout(V.current), b == null || b();
      };
      return n.addEventListener(te, R), n.addEventListener(oe, g), () => {
        n.removeEventListener(te, R), n.removeEventListener(oe, g);
      };
    }
  }, [
    d.viewport,
    v,
    b,
    s,
    D
  ]), I(() => {
    l && !d.isClosePausedRef.current && D(v);
  }, [
    l,
    v,
    d.isClosePausedRef,
    D
  ]), I(() => (F(), () => Q()), [
    F,
    Q
  ]);
  const U = Ie(() => a ? ve(a) : null, [
    a
  ]);
  return d.viewport ? /* @__PURE__ */ c(ce, null, U && /* @__PURE__ */ c(st, {
    __scopeToast: o,
    role: "status",
    "aria-live": r === "foreground" ? "assertive" : "polite",
    "aria-atomic": !0
  }, U), /* @__PURE__ */ c(rt, {
    scope: o,
    onClose: O
  }, /* @__PURE__ */ Ae(/* @__PURE__ */ c(re.ItemSlot, {
    scope: o
  }, /* @__PURE__ */ c(Ke, {
    asChild: !0,
    onEscapeKeyDown: h(f, () => {
      d.isFocusedToastEscapeKeyDownRef.current || O(), d.isFocusedToastEscapeKeyDownRef.current = !1;
    })
  }, /* @__PURE__ */ c(M.li, C({
    // Ensure toasts are announced as status list or status when focused
    role: "status",
    "aria-live": "off",
    "aria-atomic": !0,
    tabIndex: 0,
    "data-state": l ? "open" : "closed",
    "data-swipe-direction": d.swipeDirection
  }, N, {
    ref: u,
    style: {
      userSelect: "none",
      touchAction: "none",
      ...e.style
    },
    onKeyDown: h(e.onKeyDown, (n) => {
      n.key === "Escape" && (f == null || f(n.nativeEvent), n.nativeEvent.defaultPrevented || (d.isFocusedToastEscapeKeyDownRef.current = !0, O()));
    }),
    onPointerDown: h(e.onPointerDown, (n) => {
      n.button === 0 && (m.current = {
        x: n.clientX,
        y: n.clientY
      });
    }),
    onPointerMove: h(e.onPointerMove, (n) => {
      if (!m.current)
        return;
      const g = n.clientX - m.current.x, R = n.clientY - m.current.y, j = !!$.current, W = [
        "left",
        "right"
      ].includes(d.swipeDirection), X = [
        "left",
        "up"
      ].includes(d.swipeDirection) ? Math.min : Math.max, Ne = W ? X(0, g) : 0, De = W ? 0 : X(0, R), Z = n.pointerType === "touch" ? 10 : 2, Y = {
        x: Ne,
        y: De
      }, ne = {
        originalEvent: n,
        delta: Y
      };
      j ? ($.current = Y, B(Ze, T, ne, {
        discrete: !1
      })) : ae(Y, d.swipeDirection, Z) ? ($.current = Y, B(Qe, y, ne, {
        discrete: !1
      }), n.target.setPointerCapture(n.pointerId)) : (Math.abs(g) > Z || Math.abs(R) > Z) && (m.current = null);
    }),
    onPointerUp: h(e.onPointerUp, (n) => {
      const g = $.current, R = n.target;
      if (R.hasPointerCapture(n.pointerId) && R.releasePointerCapture(n.pointerId), $.current = null, m.current = null, g) {
        const j = n.currentTarget, W = {
          originalEvent: n,
          delta: g
        };
        ae(g, d.swipeDirection, d.swipeThreshold) ? B(tt, S, W, {
          discrete: !0
        }) : B(et, K, W, {
          discrete: !0
        }), j.addEventListener(
          "click",
          (X) => X.preventDefault(),
          {
            once: !0
          }
        );
      }
    })
  })))), d.viewport))) : null;
});
fe.propTypes = {
  type(e) {
    if (e.type && ![
      "foreground",
      "background"
    ].includes(e.type)) {
      const t = `Invalid prop \`type\` supplied to \`${J}\`. Expected \`foreground | background\`.`;
      return new Error(t);
    }
    return null;
  }
};
const st = (e) => {
  const { __scopeToast: t, children: o, ...r } = e, i = G(J, t), [l, p] = H(!1), [f, b] = H(!1);
  return ut(
    () => p(!0)
  ), I(() => {
    const s = window.setTimeout(
      () => b(!0),
      1e3
    );
    return () => window.clearTimeout(s);
  }, []), f ? null : /* @__PURE__ */ c(Ve, {
    asChild: !0
  }, /* @__PURE__ */ c(de, r, l && /* @__PURE__ */ c(ce, null, i.label, " ", o)));
}, at = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, ...r } = e;
  return /* @__PURE__ */ c(M.div, C({}, r, {
    ref: t
  }));
}), it = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, ...r } = e;
  return /* @__PURE__ */ c(M.div, C({}, r, {
    ref: t
  }));
}), ct = "ToastAction", be = /* @__PURE__ */ P((e, t) => {
  const { altText: o, ...r } = e;
  return o ? /* @__PURE__ */ c($e, {
    altText: o,
    asChild: !0
  }, /* @__PURE__ */ c(me, C({}, r, {
    ref: t
  }))) : null;
});
be.propTypes = {
  altText(e) {
    return e.altText ? null : new Error(`Missing prop \`altText\` expected on \`${ct}\``);
  }
};
const dt = "ToastClose", me = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, ...r } = e, i = nt(dt, o);
  return /* @__PURE__ */ c($e, {
    asChild: !0
  }, /* @__PURE__ */ c(M.button, C({
    type: "button"
  }, r, {
    ref: t,
    onClick: h(e.onClick, i.onClose)
  })));
}), $e = /* @__PURE__ */ P((e, t) => {
  const { __scopeToast: o, altText: r, ...i } = e;
  return /* @__PURE__ */ c(M.div, C({
    "data-radix-toast-announce-exclude": "",
    "data-radix-toast-announce-alt": r || void 0
  }, i, {
    ref: t
  }));
});
function ve(e) {
  const t = [];
  return Array.from(e.childNodes).forEach((r) => {
    if (r.nodeType === r.TEXT_NODE && r.textContent && t.push(r.textContent), lt(r)) {
      const i = r.ariaHidden || r.hidden || r.style.display === "none", l = r.dataset.radixToastAnnounceExclude === "";
      if (!i)
        if (l) {
          const p = r.dataset.radixToastAnnounceAlt;
          p && t.push(p);
        } else
          t.push(...ve(r));
    }
  }), t;
}
function B(e, t, o, { discrete: r }) {
  const i = o.originalEvent.currentTarget, l = new CustomEvent(e, {
    bubbles: !0,
    cancelable: !0,
    detail: o
  });
  t && i.addEventListener(e, t, {
    once: !0
  }), r ? We(i, l) : i.dispatchEvent(l);
}
const ae = (e, t, o = 0) => {
  const r = Math.abs(e.x), i = Math.abs(e.y), l = r > i;
  return t === "left" || t === "right" ? l && r > o : !l && i > o;
};
function ut(e = () => {
}) {
  const t = z(e);
  Me(() => {
    let o = 0, r = 0;
    return o = window.requestAnimationFrame(
      () => r = window.requestAnimationFrame(t)
    ), () => {
      window.cancelAnimationFrame(o), window.cancelAnimationFrame(r);
    };
  }, [
    t
  ]);
}
function lt(e) {
  return e.nodeType === e.ELEMENT_NODE;
}
function pt(e) {
  const t = [], o = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (r) => {
      const i = r.tagName === "INPUT" && r.type === "hidden";
      return r.disabled || r.hidden || i ? NodeFilter.FILTER_SKIP : r.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; o.nextNode(); )
    t.push(o.currentNode);
  return t;
}
function ee(e) {
  const t = document.activeElement;
  return e.some((o) => o === t ? !0 : (o.focus(), document.activeElement !== t));
}
const ft = pe, we = Ge, Te = ot, xe = at, ye = it, Ee = be, ge = me, he = L.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ A.jsx(
  Ee,
  {
    ref: o,
    className: k(
      "inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive",
      e
    ),
    ...t
  }
));
he.displayName = Ee.displayName;
const Ce = L.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ A.jsx(
  ge,
  {
    ref: o,
    className: k(
      "absolute right-2 top-2 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      e
    ),
    "toast-close": "",
    ...t,
    children: /* @__PURE__ */ A.jsx(Ue, { name: "Cancel", width: 20, strokeWidth: 1.5 })
  }
));
Ce.displayName = ge.displayName;
const Pe = L.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ A.jsx(
  ye,
  {
    ref: o,
    className: k("text-sm opacity-90", e),
    ...t
  }
));
Pe.displayName = ye.displayName;
const _e = L.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ A.jsx(
  xe,
  {
    ref: o,
    className: k("text-sm font-semibold", e),
    ...t
  }
));
_e.displayName = xe.displayName;
const Re = L.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ A.jsx(
  we,
  {
    ref: o,
    className: k(
      "fixed top-0 z-tooltip flex max-h-screen w-full flex-col-reverse gap-3 p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
      e
    ),
    ...t
  }
));
Re.displayName = we.displayName;
const bt = ft, mt = He(
  "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "border bg-background text-foreground",
        destructive: "destructive group border-destructive bg-destructive text-destructive-foreground"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
), Se = L.forwardRef(({ className: e, variant: t, ...o }, r) => /* @__PURE__ */ A.jsx(
  Te,
  {
    ref: r,
    className: k(mt({ variant: t }), e),
    ...o
  }
));
Se.displayName = Te.displayName;
const Dt = Object.assign(Se, {
  Action: he,
  Close: Ce,
  Description: Pe,
  Provider: bt,
  Title: _e,
  Viewport: Re
});
export {
  Dt as T
};
