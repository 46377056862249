import { $ as H, _ as $, b as Le, a as yt } from "./index-bf9f2594.js";
import * as j from "react";
import { forwardRef as x, useState as k, useRef as M, useEffect as F, useCallback as D, createElement as a, Fragment as Pt } from "react";
import { $ as Z, b as we, a as h } from "./index-5499c00c.js";
import { $ as Me } from "./index-61f4edff.js";
import { $ as L, a as St } from "./index-14f22d4f.js";
import { $ as Ke } from "./index-a88fc5a6.js";
import { $ as Ge } from "./index-1fe761a6.js";
import { b as Tt, c as Dt } from "./index-6b71901e.js";
import { h as Ot, a as Rt, $ as Ft } from "./Combination-ba9973a9.js";
import { $ as ne } from "./index-7ed7ad30.js";
import { $ as Ue, a as Nt, b as At, c as kt, d as je } from "./index-62dc40ef.js";
import { $ as ue } from "./index-e1c48dc5.js";
import { j as y } from "./jsx-runtime-944c88e2.js";
import { c as K } from "./utils-428888d8.js";
import Ce from "./Icon.es.js";
import "react-dom";
import "./index-760cd071.js";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
import "iconoir-react";
const me = "focusScope.autoFocusOnMount", ve = "focusScope.autoFocusOnUnmount", Re = {
  bubbles: !1,
  cancelable: !0
}, Lt = /* @__PURE__ */ x((e, n) => {
  const { loop: t = !1, trapped: o = !1, onMountAutoFocus: c, onUnmountAutoFocus: r, ...i } = e, [s, f] = k(null), l = Z(c), u = Z(r), d = M(null), m = H(
    n,
    (p) => f(p)
  ), v = M({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  F(() => {
    if (o) {
      let p = function(S) {
        if (v.paused || !s)
          return;
        const C = S.target;
        s.contains(C) ? d.current = C : U(d.current, {
          select: !0
        });
      }, P = function(S) {
        if (v.paused || !s)
          return;
        const C = S.relatedTarget;
        C !== null && (s.contains(C) || U(d.current, {
          select: !0
        }));
      }, _ = function(S) {
        const C = document.activeElement;
        for (const W of S)
          W.removedNodes.length > 0 && (s != null && s.contains(C) || U(s));
      };
      document.addEventListener("focusin", p), document.addEventListener("focusout", P);
      const w = new MutationObserver(_);
      return s && w.observe(s, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", p), document.removeEventListener("focusout", P), w.disconnect();
      };
    }
  }, [
    o,
    s,
    v.paused
  ]), F(() => {
    if (s) {
      Ne.add(v);
      const p = document.activeElement;
      if (!s.contains(p)) {
        const _ = new CustomEvent(me, Re);
        s.addEventListener(me, l), s.dispatchEvent(_), _.defaultPrevented || (Kt(Bt(Ve(s)), {
          select: !0
        }), document.activeElement === p && U(s));
      }
      return () => {
        s.removeEventListener(me, l), setTimeout(() => {
          const _ = new CustomEvent(ve, Re);
          s.addEventListener(ve, u), s.dispatchEvent(_), _.defaultPrevented || U(p ?? document.body, {
            select: !0
          }), s.removeEventListener(ve, u), Ne.remove(v);
        }, 0);
      };
    }
  }, [
    s,
    l,
    u,
    v
  ]);
  const g = D((p) => {
    if (!t && !o || v.paused)
      return;
    const P = p.key === "Tab" && !p.altKey && !p.ctrlKey && !p.metaKey, _ = document.activeElement;
    if (P && _) {
      const w = p.currentTarget, [S, C] = Gt(w);
      S && C ? !p.shiftKey && _ === C ? (p.preventDefault(), t && U(S, {
        select: !0
      })) : p.shiftKey && _ === S && (p.preventDefault(), t && U(C, {
        select: !0
      })) : _ === w && p.preventDefault();
    }
  }, [
    t,
    o,
    v.paused
  ]);
  return /* @__PURE__ */ a(L.div, $({
    tabIndex: -1
  }, i, {
    ref: m,
    onKeyDown: g
  }));
});
function Kt(e, { select: n = !1 } = {}) {
  const t = document.activeElement;
  for (const o of e)
    if (U(o, {
      select: n
    }), document.activeElement !== t)
      return;
}
function Gt(e) {
  const n = Ve(e), t = Fe(n, e), o = Fe(n.reverse(), e);
  return [
    t,
    o
  ];
}
function Ve(e) {
  const n = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (o) => {
      const c = o.tagName === "INPUT" && o.type === "hidden";
      return o.disabled || o.hidden || c ? NodeFilter.FILTER_SKIP : o.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); )
    n.push(t.currentNode);
  return n;
}
function Fe(e, n) {
  for (const t of e)
    if (!Ut(t, {
      upTo: n
    }))
      return t;
}
function Ut(e, { upTo: n }) {
  if (getComputedStyle(e).visibility === "hidden")
    return !0;
  for (; e; ) {
    if (n !== void 0 && e === n)
      return !1;
    if (getComputedStyle(e).display === "none")
      return !0;
    e = e.parentElement;
  }
  return !1;
}
function jt(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function U(e, { select: n = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({
      preventScroll: !0
    }), e !== t && jt(e) && n && e.select();
  }
}
const Ne = Vt();
function Vt() {
  let e = [];
  return {
    add(n) {
      const t = e[0];
      n !== t && (t == null || t.pause()), e = Ae(e, n), e.unshift(n);
    },
    remove(n) {
      var t;
      e = Ae(e, n), (t = e[0]) === null || t === void 0 || t.resume();
    }
  };
}
function Ae(e, n) {
  const t = [
    ...e
  ], o = t.indexOf(n);
  return o !== -1 && t.splice(o, 1), t;
}
function Bt(e) {
  return e.filter(
    (n) => n.tagName !== "A"
  );
}
const ge = "rovingFocusGroup.onEntryFocus", Yt = {
  bubbles: !1,
  cancelable: !0
}, Ie = "RovingFocusGroup", [xe, Be, Ht] = Ke(Ie), [Xt, Ye] = Me(Ie, [
  Ht
]), [Wt, zt] = Xt(Ie), Zt = /* @__PURE__ */ x((e, n) => /* @__PURE__ */ a(xe.Provider, {
  scope: e.__scopeRovingFocusGroup
}, /* @__PURE__ */ a(xe.Slot, {
  scope: e.__scopeRovingFocusGroup
}, /* @__PURE__ */ a(qt, $({}, e, {
  ref: n
}))))), qt = /* @__PURE__ */ x((e, n) => {
  const { __scopeRovingFocusGroup: t, orientation: o, loop: c = !1, dir: r, currentTabStopId: i, defaultCurrentTabStopId: s, onCurrentTabStopIdChange: f, onEntryFocus: l, ...u } = e, d = M(null), m = H(n, d), v = Ge(r), [g = null, p] = we({
    prop: i,
    defaultProp: s,
    onChange: f
  }), [P, _] = k(!1), w = Z(l), S = Be(t), C = M(!1), [W, q] = k(0);
  return F(() => {
    const I = d.current;
    if (I)
      return I.addEventListener(ge, w), () => I.removeEventListener(ge, w);
  }, [
    w
  ]), /* @__PURE__ */ a(Wt, {
    scope: t,
    orientation: o,
    dir: v,
    loop: c,
    currentTabStopId: g,
    onItemFocus: D(
      (I) => p(I),
      [
        p
      ]
    ),
    onItemShiftTab: D(
      () => _(!0),
      []
    ),
    onFocusableItemAdd: D(
      () => q(
        (I) => I + 1
      ),
      []
    ),
    onFocusableItemRemove: D(
      () => q(
        (I) => I - 1
      ),
      []
    )
  }, /* @__PURE__ */ a(L.div, $({
    tabIndex: P || W === 0 ? -1 : 0,
    "data-orientation": o
  }, u, {
    ref: m,
    style: {
      outline: "none",
      ...e.style
    },
    onMouseDown: h(e.onMouseDown, () => {
      C.current = !0;
    }),
    onFocus: h(e.onFocus, (I) => {
      const le = !C.current;
      if (I.target === I.currentTarget && le && !P) {
        const B = new CustomEvent(ge, Yt);
        if (I.currentTarget.dispatchEvent(B), !B.defaultPrevented) {
          const G = S().filter(
            (N) => N.focusable
          ), pe = G.find(
            (N) => N.active
          ), J = G.find(
            (N) => N.id === g
          ), Q = [
            pe,
            J,
            ...G
          ].filter(Boolean).map(
            (N) => N.ref.current
          );
          He(Q);
        }
      }
      C.current = !1;
    }),
    onBlur: h(
      e.onBlur,
      () => _(!1)
    )
  })));
}), Jt = "RovingFocusGroupItem", Qt = /* @__PURE__ */ x((e, n) => {
  const { __scopeRovingFocusGroup: t, focusable: o = !0, active: c = !1, tabStopId: r, ...i } = e, s = ne(), f = r || s, l = zt(Jt, t), u = l.currentTabStopId === f, d = Be(t), { onFocusableItemAdd: m, onFocusableItemRemove: v } = l;
  return F(() => {
    if (o)
      return m(), () => v();
  }, [
    o,
    m,
    v
  ]), /* @__PURE__ */ a(xe.ItemSlot, {
    scope: t,
    id: f,
    focusable: o,
    active: c
  }, /* @__PURE__ */ a(L.span, $({
    tabIndex: u ? 0 : -1,
    "data-orientation": l.orientation
  }, i, {
    ref: n,
    onMouseDown: h(e.onMouseDown, (g) => {
      o ? l.onItemFocus(f) : g.preventDefault();
    }),
    onFocus: h(
      e.onFocus,
      () => l.onItemFocus(f)
    ),
    onKeyDown: h(e.onKeyDown, (g) => {
      if (g.key === "Tab" && g.shiftKey) {
        l.onItemShiftTab();
        return;
      }
      if (g.target !== g.currentTarget)
        return;
      const p = nn(g, l.orientation, l.dir);
      if (p !== void 0) {
        g.preventDefault();
        let _ = d().filter(
          (w) => w.focusable
        ).map(
          (w) => w.ref.current
        );
        if (p === "last")
          _.reverse();
        else if (p === "prev" || p === "next") {
          p === "prev" && _.reverse();
          const w = _.indexOf(g.currentTarget);
          _ = l.loop ? on(_, w + 1) : _.slice(w + 1);
        }
        setTimeout(
          () => He(_)
        );
      }
    })
  })));
}), en = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function tn(e, n) {
  return n !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function nn(e, n, t) {
  const o = tn(e.key, t);
  if (!(n === "vertical" && [
    "ArrowLeft",
    "ArrowRight"
  ].includes(o)) && !(n === "horizontal" && [
    "ArrowUp",
    "ArrowDown"
  ].includes(o)))
    return en[o];
}
function He(e) {
  const n = document.activeElement;
  for (const t of e)
    if (t === n || (t.focus(), document.activeElement !== n))
      return;
}
function on(e, n) {
  return e.map(
    (t, o) => e[(n + o) % e.length]
  );
}
const cn = Zt, rn = Qt, he = [
  "Enter",
  " "
], an = [
  "ArrowDown",
  "PageUp",
  "Home"
], Xe = [
  "ArrowUp",
  "PageDown",
  "End"
], sn = [
  ...an,
  ...Xe
], dn = {
  ltr: [
    ...he,
    "ArrowRight"
  ],
  rtl: [
    ...he,
    "ArrowLeft"
  ]
}, un = {
  ltr: [
    "ArrowLeft"
  ],
  rtl: [
    "ArrowRight"
  ]
}, fe = "Menu", [oe, fn, ln] = Ke(fe), [X, We] = Me(fe, [
  ln,
  Ue,
  Ye
]), re = Ue(), ze = Ye(), [Ze, V] = X(fe), [pn, ae] = X(fe), $n = (e) => {
  const { __scopeMenu: n, open: t = !1, children: o, dir: c, onOpenChange: r, modal: i = !0 } = e, s = re(n), [f, l] = k(null), u = M(!1), d = Z(r), m = Ge(c);
  return F(() => {
    const v = () => {
      u.current = !0, document.addEventListener("pointerdown", g, {
        capture: !0,
        once: !0
      }), document.addEventListener("pointermove", g, {
        capture: !0,
        once: !0
      });
    }, g = () => u.current = !1;
    return document.addEventListener("keydown", v, {
      capture: !0
    }), () => {
      document.removeEventListener("keydown", v, {
        capture: !0
      }), document.removeEventListener("pointerdown", g, {
        capture: !0
      }), document.removeEventListener("pointermove", g, {
        capture: !0
      });
    };
  }, []), /* @__PURE__ */ a(je, s, /* @__PURE__ */ a(Ze, {
    scope: n,
    open: t,
    onOpenChange: d,
    content: f,
    onContentChange: l
  }, /* @__PURE__ */ a(pn, {
    scope: n,
    onClose: D(
      () => d(!1),
      [
        d
      ]
    ),
    isUsingKeyboardRef: u,
    dir: m,
    modal: i
  }, o)));
}, qe = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, ...o } = e, c = re(t);
  return /* @__PURE__ */ a(Nt, $({}, c, o, {
    ref: n
  }));
}), Je = "MenuPortal", [bn, Qe] = X(Je, {
  forceMount: void 0
}), mn = (e) => {
  const { __scopeMenu: n, forceMount: t, children: o, container: c } = e, r = V(Je, n);
  return /* @__PURE__ */ a(bn, {
    scope: n,
    forceMount: t
  }, /* @__PURE__ */ a(ue, {
    present: t || r.open
  }, /* @__PURE__ */ a(Tt, {
    asChild: !0,
    container: c
  }, o)));
}, R = "MenuContent", [vn, Ee] = X(R), gn = /* @__PURE__ */ x((e, n) => {
  const t = Qe(R, e.__scopeMenu), { forceMount: o = t.forceMount, ...c } = e, r = V(R, e.__scopeMenu), i = ae(R, e.__scopeMenu);
  return /* @__PURE__ */ a(oe.Provider, {
    scope: e.__scopeMenu
  }, /* @__PURE__ */ a(ue, {
    present: o || r.open
  }, /* @__PURE__ */ a(oe.Slot, {
    scope: e.__scopeMenu
  }, i.modal ? /* @__PURE__ */ a(xn, $({}, c, {
    ref: n
  })) : /* @__PURE__ */ a(hn, $({}, c, {
    ref: n
  })))));
}), xn = /* @__PURE__ */ x((e, n) => {
  const t = V(R, e.__scopeMenu), o = M(null), c = H(n, o);
  return F(() => {
    const r = o.current;
    if (r)
      return Ot(r);
  }, []), /* @__PURE__ */ a(ye, $({}, e, {
    ref: c,
    trapFocus: t.open,
    disableOutsidePointerEvents: t.open,
    disableOutsideScroll: !0,
    onFocusOutside: h(
      e.onFocusOutside,
      (r) => r.preventDefault(),
      {
        checkForDefaultPrevented: !1
      }
    ),
    onDismiss: () => t.onOpenChange(!1)
  }));
}), hn = /* @__PURE__ */ x((e, n) => {
  const t = V(R, e.__scopeMenu);
  return /* @__PURE__ */ a(ye, $({}, e, {
    ref: n,
    trapFocus: !1,
    disableOutsidePointerEvents: !1,
    disableOutsideScroll: !1,
    onDismiss: () => t.onOpenChange(!1)
  }));
}), ye = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, loop: o = !1, trapFocus: c, onOpenAutoFocus: r, onCloseAutoFocus: i, disableOutsidePointerEvents: s, onEntryFocus: f, onEscapeKeyDown: l, onPointerDownOutside: u, onFocusOutside: d, onInteractOutside: m, onDismiss: v, disableOutsideScroll: g, ...p } = e, P = V(R, t), _ = ae(R, t), w = re(t), S = ze(t), C = fn(t), [W, q] = k(null), I = M(null), le = H(n, I, P.onContentChange), B = M(0), G = M(""), pe = M(0), J = M(null), $e = M("right"), Q = M(0), N = g ? Ft : Pt, It = g ? {
    as: yt,
    allowPinchZoom: !0
  } : void 0, Et = (b) => {
    var E, O;
    const Y = G.current + b, ee = C().filter(
      (A) => !A.disabled
    ), be = document.activeElement, Te = (E = ee.find(
      (A) => A.ref.current === be
    )) === null || E === void 0 ? void 0 : E.textValue, se = ee.map(
      (A) => A.textValue
    ), te = Gn(se, Y, Te), De = (O = ee.find(
      (A) => A.textValue === te
    )) === null || O === void 0 ? void 0 : O.ref.current;
    (function A(Oe) {
      G.current = Oe, window.clearTimeout(B.current), Oe !== "" && (B.current = window.setTimeout(
        () => A(""),
        1e3
      ));
    })(Y), De && setTimeout(
      () => De.focus()
    );
  };
  F(() => () => window.clearTimeout(B.current), []), Rt();
  const z = D((b) => {
    var E, O;
    return $e.current === ((E = J.current) === null || E === void 0 ? void 0 : E.side) && jn(b, (O = J.current) === null || O === void 0 ? void 0 : O.area);
  }, []);
  return /* @__PURE__ */ a(vn, {
    scope: t,
    searchRef: G,
    onItemEnter: D((b) => {
      z(b) && b.preventDefault();
    }, [
      z
    ]),
    onItemLeave: D((b) => {
      var E;
      z(b) || ((E = I.current) === null || E === void 0 || E.focus(), q(null));
    }, [
      z
    ]),
    onTriggerLeave: D((b) => {
      z(b) && b.preventDefault();
    }, [
      z
    ]),
    pointerGraceTimerRef: pe,
    onPointerGraceIntentChange: D((b) => {
      J.current = b;
    }, [])
  }, /* @__PURE__ */ a(N, It, /* @__PURE__ */ a(Lt, {
    asChild: !0,
    trapped: c,
    onMountAutoFocus: h(r, (b) => {
      var E;
      b.preventDefault(), (E = I.current) === null || E === void 0 || E.focus();
    }),
    onUnmountAutoFocus: i
  }, /* @__PURE__ */ a(Dt, {
    asChild: !0,
    disableOutsidePointerEvents: s,
    onEscapeKeyDown: l,
    onPointerDownOutside: u,
    onFocusOutside: d,
    onInteractOutside: m,
    onDismiss: v
  }, /* @__PURE__ */ a(cn, $({
    asChild: !0
  }, S, {
    dir: _.dir,
    orientation: "vertical",
    loop: o,
    currentTabStopId: W,
    onCurrentTabStopIdChange: q,
    onEntryFocus: h(f, (b) => {
      _.isUsingKeyboardRef.current || b.preventDefault();
    })
  }), /* @__PURE__ */ a(At, $({
    role: "menu",
    "aria-orientation": "vertical",
    "data-state": at(P.open),
    "data-radix-menu-content": "",
    dir: _.dir
  }, w, p, {
    ref: le,
    style: {
      outline: "none",
      ...p.style
    },
    onKeyDown: h(p.onKeyDown, (b) => {
      const O = b.target.closest("[data-radix-menu-content]") === b.currentTarget, Y = b.ctrlKey || b.altKey || b.metaKey, ee = b.key.length === 1;
      O && (b.key === "Tab" && b.preventDefault(), !Y && ee && Et(b.key));
      const be = I.current;
      if (b.target !== be || !sn.includes(b.key))
        return;
      b.preventDefault();
      const se = C().filter(
        (te) => !te.disabled
      ).map(
        (te) => te.ref.current
      );
      Xe.includes(b.key) && se.reverse(), Ln(se);
    }),
    onBlur: h(e.onBlur, (b) => {
      b.currentTarget.contains(b.target) || (window.clearTimeout(B.current), G.current = "");
    }),
    onPointerMove: h(e.onPointerMove, ce((b) => {
      const E = b.target, O = Q.current !== b.clientX;
      if (b.currentTarget.contains(E) && O) {
        const Y = b.clientX > Q.current ? "right" : "left";
        $e.current = Y, Q.current = b.clientX;
      }
    }))
  })))))));
}), et = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, ...o } = e;
  return /* @__PURE__ */ a(L.div, $({
    role: "group"
  }, o, {
    ref: n
  }));
}), _n = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, ...o } = e;
  return /* @__PURE__ */ a(L.div, $({}, o, {
    ref: n
  }));
}), _e = "MenuItem", ke = "menu.itemSelect", Pe = /* @__PURE__ */ x((e, n) => {
  const { disabled: t = !1, onSelect: o, ...c } = e, r = M(null), i = ae(_e, e.__scopeMenu), s = Ee(_e, e.__scopeMenu), f = H(n, r), l = M(!1), u = () => {
    const d = r.current;
    if (!t && d) {
      const m = new CustomEvent(ke, {
        bubbles: !0,
        cancelable: !0
      });
      d.addEventListener(
        ke,
        (v) => o == null ? void 0 : o(v),
        {
          once: !0
        }
      ), St(d, m), m.defaultPrevented ? l.current = !1 : i.onClose();
    }
  };
  return /* @__PURE__ */ a(tt, $({}, c, {
    ref: f,
    disabled: t,
    onClick: h(e.onClick, u),
    onPointerDown: (d) => {
      var m;
      (m = e.onPointerDown) === null || m === void 0 || m.call(e, d), l.current = !0;
    },
    onPointerUp: h(e.onPointerUp, (d) => {
      var m;
      l.current || (m = d.currentTarget) === null || m === void 0 || m.click();
    }),
    onKeyDown: h(e.onKeyDown, (d) => {
      const m = s.searchRef.current !== "";
      t || m && d.key === " " || he.includes(d.key) && (d.currentTarget.click(), d.preventDefault());
    })
  }));
}), tt = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, disabled: o = !1, textValue: c, ...r } = e, i = Ee(_e, t), s = ze(t), f = M(null), l = H(n, f), [u, d] = k(!1), [m, v] = k("");
  return F(() => {
    const g = f.current;
    if (g) {
      var p;
      v(((p = g.textContent) !== null && p !== void 0 ? p : "").trim());
    }
  }, [
    r.children
  ]), /* @__PURE__ */ a(oe.ItemSlot, {
    scope: t,
    disabled: o,
    textValue: c ?? m
  }, /* @__PURE__ */ a(rn, $({
    asChild: !0
  }, s, {
    focusable: !o
  }), /* @__PURE__ */ a(L.div, $({
    role: "menuitem",
    "data-highlighted": u ? "" : void 0,
    "aria-disabled": o || void 0,
    "data-disabled": o ? "" : void 0
  }, r, {
    ref: l,
    onPointerMove: h(e.onPointerMove, ce((g) => {
      o ? i.onItemLeave(g) : (i.onItemEnter(g), g.defaultPrevented || g.currentTarget.focus());
    })),
    onPointerLeave: h(e.onPointerLeave, ce(
      (g) => i.onItemLeave(g)
    )),
    onFocus: h(
      e.onFocus,
      () => d(!0)
    ),
    onBlur: h(
      e.onBlur,
      () => d(!1)
    )
  }))));
}), wn = /* @__PURE__ */ x((e, n) => {
  const { checked: t = !1, onCheckedChange: o, ...c } = e;
  return /* @__PURE__ */ a(ot, {
    scope: e.__scopeMenu,
    checked: t
  }, /* @__PURE__ */ a(Pe, $({
    role: "menuitemcheckbox",
    "aria-checked": ie(t) ? "mixed" : t
  }, c, {
    ref: n,
    "data-state": Se(t),
    onSelect: h(
      c.onSelect,
      () => o == null ? void 0 : o(ie(t) ? !0 : !t),
      {
        checkForDefaultPrevented: !1
      }
    )
  })));
}), Mn = "MenuRadioGroup", [Cn, In] = X(Mn, {
  value: void 0,
  onValueChange: () => {
  }
}), En = /* @__PURE__ */ x((e, n) => {
  const { value: t, onValueChange: o, ...c } = e, r = Z(o);
  return /* @__PURE__ */ a(Cn, {
    scope: e.__scopeMenu,
    value: t,
    onValueChange: r
  }, /* @__PURE__ */ a(et, $({}, c, {
    ref: n
  })));
}), yn = "MenuRadioItem", Pn = /* @__PURE__ */ x((e, n) => {
  const { value: t, ...o } = e, c = In(yn, e.__scopeMenu), r = t === c.value;
  return /* @__PURE__ */ a(ot, {
    scope: e.__scopeMenu,
    checked: r
  }, /* @__PURE__ */ a(Pe, $({
    role: "menuitemradio",
    "aria-checked": r
  }, o, {
    ref: n,
    "data-state": Se(r),
    onSelect: h(o.onSelect, () => {
      var i;
      return (i = c.onValueChange) === null || i === void 0 ? void 0 : i.call(c, t);
    }, {
      checkForDefaultPrevented: !1
    })
  })));
}), nt = "MenuItemIndicator", [ot, Sn] = X(nt, {
  checked: !1
}), Tn = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, forceMount: o, ...c } = e, r = Sn(nt, t);
  return /* @__PURE__ */ a(ue, {
    present: o || ie(r.checked) || r.checked === !0
  }, /* @__PURE__ */ a(L.span, $({}, c, {
    ref: n,
    "data-state": Se(r.checked)
  })));
}), Dn = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, ...o } = e;
  return /* @__PURE__ */ a(L.div, $({
    role: "separator",
    "aria-orientation": "horizontal"
  }, o, {
    ref: n
  }));
}), On = /* @__PURE__ */ x((e, n) => {
  const { __scopeMenu: t, ...o } = e, c = re(t);
  return /* @__PURE__ */ a(kt, $({}, c, o, {
    ref: n
  }));
}), ct = "MenuSub", [Rn, rt] = X(ct), Fn = (e) => {
  const { __scopeMenu: n, children: t, open: o = !1, onOpenChange: c } = e, r = V(ct, n), i = re(n), [s, f] = k(null), [l, u] = k(null), d = Z(c);
  return F(() => (r.open === !1 && d(!1), () => d(!1)), [
    r.open,
    d
  ]), /* @__PURE__ */ a(je, i, /* @__PURE__ */ a(Ze, {
    scope: n,
    open: o,
    onOpenChange: d,
    content: l,
    onContentChange: u
  }, /* @__PURE__ */ a(Rn, {
    scope: n,
    contentId: ne(),
    triggerId: ne(),
    trigger: s,
    onTriggerChange: f
  }, t)));
}, de = "MenuSubTrigger", Nn = /* @__PURE__ */ x((e, n) => {
  const t = V(de, e.__scopeMenu), o = ae(de, e.__scopeMenu), c = rt(de, e.__scopeMenu), r = Ee(de, e.__scopeMenu), i = M(null), { pointerGraceTimerRef: s, onPointerGraceIntentChange: f } = r, l = {
    __scopeMenu: e.__scopeMenu
  }, u = D(() => {
    i.current && window.clearTimeout(i.current), i.current = null;
  }, []);
  return F(
    () => u,
    [
      u
    ]
  ), F(() => {
    const d = s.current;
    return () => {
      window.clearTimeout(d), f(null);
    };
  }, [
    s,
    f
  ]), /* @__PURE__ */ a(qe, $({
    asChild: !0
  }, l), /* @__PURE__ */ a(tt, $({
    id: c.triggerId,
    "aria-haspopup": "menu",
    "aria-expanded": t.open,
    "aria-controls": c.contentId,
    "data-state": at(t.open)
  }, e, {
    ref: Le(n, c.onTriggerChange),
    onClick: (d) => {
      var m;
      (m = e.onClick) === null || m === void 0 || m.call(e, d), !(e.disabled || d.defaultPrevented) && (d.currentTarget.focus(), t.open || t.onOpenChange(!0));
    },
    onPointerMove: h(e.onPointerMove, ce((d) => {
      r.onItemEnter(d), !d.defaultPrevented && !e.disabled && !t.open && !i.current && (r.onPointerGraceIntentChange(null), i.current = window.setTimeout(() => {
        t.onOpenChange(!0), u();
      }, 100));
    })),
    onPointerLeave: h(e.onPointerLeave, ce((d) => {
      var m;
      u();
      const v = (m = t.content) === null || m === void 0 ? void 0 : m.getBoundingClientRect();
      if (v) {
        var g;
        const p = (g = t.content) === null || g === void 0 ? void 0 : g.dataset.side, P = p === "right", _ = P ? -5 : 5, w = v[P ? "left" : "right"], S = v[P ? "right" : "left"];
        r.onPointerGraceIntentChange({
          area: [
            // consistently within polygon bounds
            {
              x: d.clientX + _,
              y: d.clientY
            },
            {
              x: w,
              y: v.top
            },
            {
              x: S,
              y: v.top
            },
            {
              x: S,
              y: v.bottom
            },
            {
              x: w,
              y: v.bottom
            }
          ],
          side: p
        }), window.clearTimeout(s.current), s.current = window.setTimeout(
          () => r.onPointerGraceIntentChange(null),
          300
        );
      } else {
        if (r.onTriggerLeave(d), d.defaultPrevented)
          return;
        r.onPointerGraceIntentChange(null);
      }
    })),
    onKeyDown: h(e.onKeyDown, (d) => {
      const m = r.searchRef.current !== "";
      if (!(e.disabled || m && d.key === " ") && dn[o.dir].includes(d.key)) {
        var v;
        t.onOpenChange(!0), (v = t.content) === null || v === void 0 || v.focus(), d.preventDefault();
      }
    })
  })));
}), An = "MenuSubContent", kn = /* @__PURE__ */ x((e, n) => {
  const t = Qe(R, e.__scopeMenu), { forceMount: o = t.forceMount, ...c } = e, r = V(R, e.__scopeMenu), i = ae(R, e.__scopeMenu), s = rt(An, e.__scopeMenu), f = M(null), l = H(n, f);
  return /* @__PURE__ */ a(oe.Provider, {
    scope: e.__scopeMenu
  }, /* @__PURE__ */ a(ue, {
    present: o || r.open
  }, /* @__PURE__ */ a(oe.Slot, {
    scope: e.__scopeMenu
  }, /* @__PURE__ */ a(ye, $({
    id: s.contentId,
    "aria-labelledby": s.triggerId
  }, c, {
    ref: l,
    align: "start",
    side: i.dir === "rtl" ? "left" : "right",
    disableOutsidePointerEvents: !1,
    disableOutsideScroll: !1,
    trapFocus: !1,
    onOpenAutoFocus: (u) => {
      var d;
      i.isUsingKeyboardRef.current && ((d = f.current) === null || d === void 0 || d.focus()), u.preventDefault();
    },
    onCloseAutoFocus: (u) => u.preventDefault(),
    onFocusOutside: h(e.onFocusOutside, (u) => {
      u.target !== s.trigger && r.onOpenChange(!1);
    }),
    onEscapeKeyDown: h(e.onEscapeKeyDown, (u) => {
      i.onClose(), u.preventDefault();
    }),
    onKeyDown: h(e.onKeyDown, (u) => {
      const d = u.currentTarget.contains(u.target), m = un[i.dir].includes(u.key);
      if (d && m) {
        var v;
        r.onOpenChange(!1), (v = s.trigger) === null || v === void 0 || v.focus(), u.preventDefault();
      }
    })
  })))));
});
function at(e) {
  return e ? "open" : "closed";
}
function ie(e) {
  return e === "indeterminate";
}
function Se(e) {
  return ie(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
function Ln(e) {
  const n = document.activeElement;
  for (const t of e)
    if (t === n || (t.focus(), document.activeElement !== n))
      return;
}
function Kn(e, n) {
  return e.map(
    (t, o) => e[(n + o) % e.length]
  );
}
function Gn(e, n, t) {
  const c = n.length > 1 && Array.from(n).every(
    (l) => l === n[0]
  ) ? n[0] : n, r = t ? e.indexOf(t) : -1;
  let i = Kn(e, Math.max(r, 0));
  c.length === 1 && (i = i.filter(
    (l) => l !== t
  ));
  const f = i.find(
    (l) => l.toLowerCase().startsWith(c.toLowerCase())
  );
  return f !== t ? f : void 0;
}
function Un(e, n) {
  const { x: t, y: o } = e;
  let c = !1;
  for (let r = 0, i = n.length - 1; r < n.length; i = r++) {
    const s = n[r].x, f = n[r].y, l = n[i].x, u = n[i].y;
    f > o != u > o && t < (l - s) * (o - f) / (u - f) + s && (c = !c);
  }
  return c;
}
function jn(e, n) {
  if (!n)
    return !1;
  const t = {
    x: e.clientX,
    y: e.clientY
  };
  return Un(t, n);
}
function ce(e) {
  return (n) => n.pointerType === "mouse" ? e(n) : void 0;
}
const Vn = $n, Bn = qe, Yn = mn, Hn = gn, Xn = et, Wn = _n, zn = Pe, Zn = wn, qn = En, Jn = Pn, Qn = Tn, eo = Dn, to = On, no = Fn, oo = Nn, co = kn, st = "DropdownMenu", [ro, uc] = Me(st, [
  We
]), T = We(), [ao, dt] = ro(st), so = (e) => {
  const { __scopeDropdownMenu: n, children: t, dir: o, open: c, defaultOpen: r, onOpenChange: i, modal: s = !0 } = e, f = T(n), l = M(null), [u = !1, d] = we({
    prop: c,
    defaultProp: r,
    onChange: i
  });
  return /* @__PURE__ */ a(ao, {
    scope: n,
    triggerId: ne(),
    triggerRef: l,
    contentId: ne(),
    open: u,
    onOpenChange: d,
    onOpenToggle: D(
      () => d(
        (m) => !m
      ),
      [
        d
      ]
    ),
    modal: s
  }, /* @__PURE__ */ a(Vn, $({}, f, {
    open: u,
    onOpenChange: d,
    dir: o,
    modal: s
  }), t));
}, io = "DropdownMenuTrigger", uo = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, disabled: o = !1, ...c } = e, r = dt(io, t), i = T(t);
  return /* @__PURE__ */ a(Bn, $({
    asChild: !0
  }, i), /* @__PURE__ */ a(L.button, $({
    type: "button",
    id: r.triggerId,
    "aria-haspopup": "menu",
    "aria-expanded": r.open,
    "aria-controls": r.open ? r.contentId : void 0,
    "data-state": r.open ? "open" : "closed",
    "data-disabled": o ? "" : void 0,
    disabled: o
  }, c, {
    ref: Le(n, r.triggerRef),
    onPointerDown: h(e.onPointerDown, (s) => {
      !o && s.button === 0 && s.ctrlKey === !1 && (r.onOpenToggle(), r.open || s.preventDefault());
    }),
    onKeyDown: h(e.onKeyDown, (s) => {
      o || ([
        "Enter",
        " "
      ].includes(s.key) && r.onOpenToggle(), s.key === "ArrowDown" && r.onOpenChange(!0), [
        "Enter",
        " ",
        "ArrowDown"
      ].includes(s.key) && s.preventDefault());
    })
  })));
}), fo = (e) => {
  const { __scopeDropdownMenu: n, ...t } = e, o = T(n);
  return /* @__PURE__ */ a(Yn, $({}, o, t));
}, lo = "DropdownMenuContent", po = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = dt(lo, t), r = T(t), i = M(!1);
  return /* @__PURE__ */ a(Hn, $({
    id: c.contentId,
    "aria-labelledby": c.triggerId
  }, r, o, {
    ref: n,
    onCloseAutoFocus: h(e.onCloseAutoFocus, (s) => {
      var f;
      i.current || (f = c.triggerRef.current) === null || f === void 0 || f.focus(), i.current = !1, s.preventDefault();
    }),
    onInteractOutside: h(e.onInteractOutside, (s) => {
      const f = s.detail.originalEvent, l = f.button === 0 && f.ctrlKey === !0, u = f.button === 2 || l;
      (!c.modal || u) && (i.current = !0);
    }),
    style: {
      ...e.style,
      "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
      "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
      "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }));
}), $o = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(Xn, $({}, c, o, {
    ref: n
  }));
}), bo = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(Wn, $({}, c, o, {
    ref: n
  }));
}), mo = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(zn, $({}, c, o, {
    ref: n
  }));
}), vo = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(Zn, $({}, c, o, {
    ref: n
  }));
}), go = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(qn, $({}, c, o, {
    ref: n
  }));
}), xo = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(Jn, $({}, c, o, {
    ref: n
  }));
}), ho = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(Qn, $({}, c, o, {
    ref: n
  }));
}), _o = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(eo, $({}, c, o, {
    ref: n
  }));
}), wo = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(to, $({}, c, o, {
    ref: n
  }));
}), Mo = (e) => {
  const { __scopeDropdownMenu: n, children: t, open: o, onOpenChange: c, defaultOpen: r } = e, i = T(n), [s = !1, f] = we({
    prop: o,
    defaultProp: r,
    onChange: c
  });
  return /* @__PURE__ */ a(no, $({}, i, {
    open: s,
    onOpenChange: f
  }), t);
}, Co = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(oo, $({}, c, o, {
    ref: n
  }));
}), Io = /* @__PURE__ */ x((e, n) => {
  const { __scopeDropdownMenu: t, ...o } = e, c = T(t);
  return /* @__PURE__ */ a(co, $({}, c, o, {
    ref: n,
    style: {
      ...e.style,
      "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
      "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
      "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }));
}), Eo = so, yo = uo, Po = fo, So = po, To = $o, Do = bo, Oo = mo, Ro = vo, Fo = go, No = xo, it = ho, Ao = _o, ko = wo, Lo = Mo, Ko = Co, Go = Io, ut = ko;
ut.displayName = "Arrow";
const ft = j.forwardRef(({ className: e, children: n, checked: t, ...o }, c) => /* @__PURE__ */ y.jsxs(
  Ro,
  {
    ref: c,
    className: K(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    checked: t,
    ...o,
    children: [
      /* @__PURE__ */ y.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ y.jsx(it, { children: /* @__PURE__ */ y.jsx(Ce, { name: "Check" }) }) }),
      n
    ]
  }
));
ft.displayName = "CheckboxItem";
const lt = j.forwardRef(({ className: e, sideOffset: n = 4, withPortal: t, ...o }, c) => {
  const r = /* @__PURE__ */ y.jsx(
    So,
    {
      ref: c,
      sideOffset: n,
      className: K(
        "DropdownMenuContent z-tooltip min-w-[8rem] overflow-hidden overflow-y-auto rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        e
      ),
      ...o
    }
  );
  return t ? /* @__PURE__ */ y.jsx(Vo.Portal, { children: r }) : r;
});
lt.displayName = "Content";
const pt = To;
pt.displayName = "Group";
const $t = j.forwardRef(({ className: e, inset: n, ...t }, o) => /* @__PURE__ */ y.jsx(
  Oo,
  {
    ref: o,
    className: K(
      "relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      n && "pl-8",
      e
    ),
    ...t
  }
));
$t.displayName = "Item";
const bt = j.forwardRef(({ className: e, inset: n, ...t }, o) => /* @__PURE__ */ y.jsx(
  Do,
  {
    ref: o,
    className: K(
      "px-2 py-1.5 text-sm font-semibold",
      n && "pl-8",
      e
    ),
    ...t
  }
));
bt.displayName = "Label";
const mt = Po;
mt.displayName = "Portal";
const vt = Fo;
vt.displayName = "RadioGroup";
const gt = j.forwardRef(({ className: e, children: n, ...t }, o) => /* @__PURE__ */ y.jsxs(
  No,
  {
    ref: o,
    className: K(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    ...t,
    children: [
      /* @__PURE__ */ y.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ y.jsx(it, { children: /* @__PURE__ */ y.jsx(Ce, { name: "Circle" }) }) }),
      n
    ]
  }
));
gt.displayName = "RadioItem";
const xt = j.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  Ao,
  {
    ref: t,
    className: K("-mx-1 my-1 h-px bg-muted", e),
    ...n
  }
));
xt.displayName = "Separator";
const ht = ({
  className: e,
  ...n
}) => /* @__PURE__ */ y.jsx(
  "span",
  {
    className: K("ml-auto text-xs tracking-widest opacity-60", e),
    ...n
  }
);
ht.displayName = "Shortcut";
const _t = Lo;
_t.displayName = "Sub";
const wt = j.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  Go,
  {
    ref: t,
    className: K(
      "z-tooltip min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      e
    ),
    ...n
  }
));
wt.displayName = "SubContent";
const Mt = j.forwardRef(({ className: e, inset: n, children: t, ...o }, c) => /* @__PURE__ */ y.jsxs(
  Ko,
  {
    ref: c,
    className: K(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      n && "pl-8",
      e
    ),
    ...o,
    children: [
      t,
      /* @__PURE__ */ y.jsx(Ce, { name: "NavArrowRight", width: 24, height: 24 })
    ]
  }
));
Mt.displayName = "SubTrigger";
const Ct = yo;
Ct.displayName = "Trigger";
const Uo = Eo, jo = Object.assign(Uo, {
  Arrow: ut,
  Trigger: Ct,
  Group: pt,
  Portal: mt,
  Sub: _t,
  RadioGroup: vt,
  SubTrigger: Mt,
  SubContent: wt,
  Content: lt,
  Item: $t,
  CheckboxItem: ft,
  RadioItem: gt,
  Label: bt,
  Separator: xt,
  Shortcut: ht
}), Vo = jo;
export {
  Vo as default
};
