import { j as h } from "./jsx-runtime-944c88e2.js";
import { $ as R, _ as m } from "./index-bf9f2594.js";
import * as j from "react";
import { forwardRef as g, useState as q, useRef as v, createElement as b, useEffect as B } from "react";
import { b as I, a as M } from "./index-5499c00c.js";
import { $ as T } from "./index-61f4edff.js";
import { $ as H } from "./index-6460524a.js";
import { $ as A } from "./index-a1ad2cc2.js";
import { $ as C } from "./index-14f22d4f.js";
import { c as w } from "./utils-428888d8.js";
import "react-dom";
const S = "Switch", [O, ae] = T(S), [z, D] = O(S), F = /* @__PURE__ */ g((t, a) => {
  const { __scopeSwitch: e, name: c, checked: o, defaultChecked: i, required: n, disabled: r, value: d = "on", onCheckedChange: l, ...k } = t, [s, p] = q(null), P = R(
    a,
    (u) => p(u)
  ), $ = v(!1), x = s ? !!s.closest("form") : !0, [f = !1, E] = I({
    prop: o,
    defaultProp: i,
    onChange: l
  });
  return /* @__PURE__ */ b(z, {
    scope: e,
    checked: f,
    disabled: r
  }, /* @__PURE__ */ b(C.button, m({
    type: "button",
    role: "switch",
    "aria-checked": f,
    "aria-required": n,
    "data-state": _(f),
    "data-disabled": r ? "" : void 0,
    disabled: r,
    value: d
  }, k, {
    ref: P,
    onClick: M(t.onClick, (u) => {
      E(
        (N) => !N
      ), x && ($.current = u.isPropagationStopped(), $.current || u.stopPropagation());
    })
  })), x && /* @__PURE__ */ b(W, {
    control: s,
    bubbles: !$.current,
    name: c,
    value: d,
    checked: f,
    required: n,
    disabled: r,
    style: {
      transform: "translateX(-100%)"
    }
  }));
}), L = "SwitchThumb", U = /* @__PURE__ */ g((t, a) => {
  const { __scopeSwitch: e, ...c } = t, o = D(L, e);
  return /* @__PURE__ */ b(C.span, m({
    "data-state": _(o.checked),
    "data-disabled": o.disabled ? "" : void 0
  }, c, {
    ref: a
  }));
}), W = (t) => {
  const { control: a, checked: e, bubbles: c = !0, ...o } = t, i = v(null), n = H(e), r = A(a);
  return B(() => {
    const d = i.current, l = window.HTMLInputElement.prototype, s = Object.getOwnPropertyDescriptor(l, "checked").set;
    if (n !== e && s) {
      const p = new Event("click", {
        bubbles: c
      });
      s.call(d, e), d.dispatchEvent(p);
    }
  }, [
    n,
    e,
    c
  ]), /* @__PURE__ */ b("input", m({
    type: "checkbox",
    "aria-hidden": !0,
    defaultChecked: e
  }, o, {
    tabIndex: -1,
    ref: i,
    style: {
      ...t.style,
      ...r,
      position: "absolute",
      pointerEvents: "none",
      opacity: 0,
      margin: 0
    }
  }));
};
function _(t) {
  return t ? "checked" : "unchecked";
}
const y = F, X = U, G = j.forwardRef(({ className: t, checked: a, children: e, ...c }, o) => /* @__PURE__ */ h.jsxs(
  y,
  {
    className: w(
      "peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-green-flowkit data-[state=unchecked]:bg-input",
      { "w-[80px]": !!e },
      t
    ),
    ...c,
    ref: o,
    children: [
      /* @__PURE__ */ h.jsx(
        "span",
        {
          "data-state": a ? "checked" : "unchecked",
          className: "text-sm data-[state=checked]:mr-auto data-[state=checked]:ml-1 data-[state=checked]:text-white data-[state=unchecked]:mr-1 data-[state=unchecked]:ml-auto max-w-[60px] truncate",
          children: e
        }
      ),
      /* @__PURE__ */ h.jsx(
        X,
        {
          className: w(
            "absolute pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
            { "data-[state=checked]:translate-x-14": !!e }
          )
        }
      )
    ]
  }
));
G.displayName = y.displayName;
export {
  G as default
};
