import { j as f } from "./jsx-runtime-944c88e2.js";
import { _ as c } from "./index-bf9f2594.js";
import * as b from "react";
import { forwardRef as m, createElement as l } from "react";
import { b as p, a as u } from "./index-5499c00c.js";
import { $ as g } from "./index-14f22d4f.js";
import { c as x } from "./index-31854cc2.js";
import { c as $ } from "./utils-428888d8.js";
import "react-dom";
const v = /* @__PURE__ */ m((e, t) => {
  const { pressed: r, defaultPressed: o = !1, onPressedChange: a, ...d } = e, [s = !1, i] = p({
    prop: r,
    onChange: a,
    defaultProp: o
  });
  return /* @__PURE__ */ l(g.button, c({
    type: "button",
    "aria-pressed": s,
    "data-state": s ? "on" : "off",
    "data-disabled": e.disabled ? "" : void 0
  }, d, {
    ref: t,
    onClick: u(e.onClick, () => {
      e.disabled || i(!s);
    })
  }));
}), n = v, h = x(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors data-[state=on]:bg-accent data-[state=on]:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ring-offset-background hover:bg-muted hover:text-muted-foreground",
  {
    variants: {
      variant: {
        default: "bg-transparent",
        outline: "bg-transparent border border-input hover:bg-accent hover:text-accent-foreground"
      },
      size: {
        md: "h-10 py-2 px-4",
        sm: "h-8 px-2 rounded-md",
        lg: "h-11 px-8 rounded-md"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "md"
    }
  }
), P = b.forwardRef(({ className: e, variant: t, size: r, ...o }, a) => /* @__PURE__ */ f.jsx(
  n,
  {
    ref: a,
    className: $(h({ variant: t, size: r, className: e })),
    ...o
  }
));
P.displayName = n.displayName;
export {
  P as default
};
