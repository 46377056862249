import { j as f } from "./jsx-runtime-944c88e2.js";
import { _ as l } from "./index-bf9f2594.js";
import { forwardRef as r, createElement as s } from "react";
import { $ as d } from "./index-14f22d4f.js";
import { c as i } from "./utils-428888d8.js";
import { c as m } from "./index-31854cc2.js";
import "react-dom";
const c = /* @__PURE__ */ r((e, o) => /* @__PURE__ */ s(d.label, l({}, e, {
  ref: o,
  onMouseDown: (a) => {
    var t;
    (t = e.onMouseDown) === null || t === void 0 || t.call(e, a), !a.defaultPrevented && a.detail > 1 && a.preventDefault();
  }
}))), u = c, p = m(
  "text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      variant: {
        default: "text-primary",
        secondary: "text-gray-neutral-70"
      },
      fontWeight: {
        normal: "font-normal",
        default: "font-medium",
        bold: "font-bold"
      },
      defaultVariants: {
        variant: "default",
        fontWeight: "default"
      }
    }
  }
), b = r(({ className: e, variant: o, fontWeight: a, ...t }, n) => /* @__PURE__ */ f.jsx(
  u,
  {
    ref: n,
    className: i(p({ variant: o, fontWeight: a, className: e })),
    ...t
  }
));
b.displayName = "Label";
export {
  b as default
};
