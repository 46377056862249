import { j as t } from "./jsx-runtime-944c88e2.js";
import * as j from "react";
import { g as r, c as e } from "./utils-428888d8.js";
import { W as I, I as R } from "./constants-cf1341a1.js";
import "./index-31854cc2.js";
const n = j.forwardRef(
  ({ className: s = "", wrapClassName: a = "", error: f = !1, type: p, variant: m, children: i, ...x }, c) => {
    const u = r(i, "Prefix"), N = r(i, "Suffix"), d = r(i, "Label");
    return /* @__PURE__ */ t.jsxs(
      "div",
      {
        "aria-invalid": f,
        className: e(I({ variant: m, error: f, className: a })),
        children: [
          d,
          u,
          /* @__PURE__ */ t.jsx(
            "input",
            {
              type: p,
              className: e(R({ variant: m, className: s })),
              ref: c,
              ...x
            }
          ),
          N
        ]
      }
    );
  }
);
n.displayName = "Input";
const o = ({ children: s, className: a = "" }) => /* @__PURE__ */ t.jsx("div", { className: e("text-gray-neutral-100", a), children: s });
o.displayName = "Prefix";
const l = ({ children: s, className: a = "" }) => /* @__PURE__ */ t.jsx("div", { className: e("text-gray-neutral-100", a), children: s });
l.displayName = "Suffix";
const S = Object.assign(n, { Prefix: o, Suffix: l });
export {
  S as default
};
