import { j as s } from "./jsx-runtime-944c88e2.js";
import { _ as m } from "./index-bf9f2594.js";
import * as $ from "react";
import { forwardRef as u, useState as p, createElement as l, useEffect as v } from "react";
import { $ as j, a as N } from "./index-61f4edff.js";
import { $ as R } from "./index-5499c00c.js";
import { $ as b } from "./index-14f22d4f.js";
import { c as g } from "./utils-428888d8.js";
import f from "./Tooltip.es.js";
import { c as I } from "./index-31854cc2.js";
import "react-dom";
import "./index-6b71901e.js";
import "./index-760cd071.js";
import "./index-7ed7ad30.js";
import "./index-62dc40ef.js";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
import "./index-e1c48dc5.js";
import "./index-1ac05127.js";
const A = "Avatar", [C, ce] = j(A), [y, h] = C(A), E = /* @__PURE__ */ u((e, t) => {
  const { __scopeAvatar: a, ...r } = e, [o, c] = p("idle");
  return /* @__PURE__ */ l(y, {
    scope: a,
    imageLoadingStatus: o,
    onImageLoadingStatusChange: c
  }, /* @__PURE__ */ l(b.span, m({}, r, {
    ref: t
  })));
}), M = "AvatarImage", T = /* @__PURE__ */ u((e, t) => {
  const { __scopeAvatar: a, src: r, onLoadingStatusChange: o = () => {
  }, ...c } = e, d = h(M, a), n = P(r), i = R((x) => {
    o(x), d.onImageLoadingStatusChange(x);
  });
  return N(() => {
    n !== "idle" && i(n);
  }, [
    n,
    i
  ]), n === "loaded" ? /* @__PURE__ */ l(b.img, m({}, c, {
    ref: t,
    src: r
  })) : null;
}), k = "AvatarFallback", F = /* @__PURE__ */ u((e, t) => {
  const { __scopeAvatar: a, delayMs: r, ...o } = e, c = h(k, a), [d, n] = p(r === void 0);
  return v(() => {
    if (r !== void 0) {
      const i = window.setTimeout(
        () => n(!0),
        r
      );
      return () => window.clearTimeout(i);
    }
  }, [
    r
  ]), d && c.imageLoadingStatus !== "loaded" ? /* @__PURE__ */ l(b.span, m({}, o, {
    ref: t
  })) : null;
});
function P(e) {
  const [t, a] = p("idle");
  return v(() => {
    if (!e) {
      a("error");
      return;
    }
    let r = !0;
    const o = new window.Image(), c = (d) => () => {
      r && a(d);
    };
    return a("loading"), o.onload = c("loaded"), o.onerror = c("error"), o.src = e, () => {
      r = !1;
    };
  }, [
    e
  ]), t;
}
const w = E, V = T, z = F, q = I(
  "relative flex shrink-0 overflow-hidden rounded-full",
  {
    variants: {
      size: {
        default: "h-10 w-10",
        xxs: "h-4 w-4",
        xs: "h-6 w-6 text-xs",
        sm: "h-8 w-8",
        lg: "h-11 w-11",
        xl: "h-16 w-16"
      }
    },
    defaultVariants: {
      size: "default"
    }
  }
), _ = $.forwardRef(({ className: e = "", tooltip: t, size: a, ...r }, o) => {
  const c = /* @__PURE__ */ s.jsx(
    w,
    {
      ref: o,
      className: g(q({ size: a, className: e })),
      ...r
    }
  );
  return t ? /* @__PURE__ */ s.jsx(f.Provider, { children: /* @__PURE__ */ s.jsxs(f, { children: [
    /* @__PURE__ */ s.jsx(f.Trigger, { children: c }),
    /* @__PURE__ */ s.jsx(f.Content, { children: t })
  ] }) }) : /* @__PURE__ */ s.jsx(s.Fragment, { children: c });
});
_.displayName = w.displayName;
const S = $.forwardRef(({ className: e, ...t }, a) => /* @__PURE__ */ s.jsx(
  V,
  {
    ref: a,
    className: g("aspect-square h-full w-full object-cover", e),
    ...t
  }
));
S.displayName = "Image";
const L = $.forwardRef(({ className: e, ...t }, a) => /* @__PURE__ */ s.jsx(
  z,
  {
    ref: a,
    className: g(
      "flex h-full w-full items-center justify-center rounded-full bg-muted",
      e
    ),
    ...t
  }
));
L.displayName = "Fallback";
const se = Object.assign(_, { Image: S, Fallback: L });
export {
  se as default
};
